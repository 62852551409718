#video-bg {
    position: relative;
    width: auto;
    min-width: 100%;
    max-width: 200%;
    height: 100%;
    background-size: cover;
  }
  video {
    display: block;
  }
  .video-container {
    width: 50%;
    max-height: 600px;
    overflow: hidden;
    position: fixed;
    top: 0;
    right: 0;
    z-index: -100;
  }