a { color: white; text-decoration: none; }

.arrow {
  text-align: center;
  margin: 8% 20rem;
  margin-left: 8em;
  align-items: center;
  position: relative;
  padding-top: 6em;
  align-content: center;
}
.bounce {
  -moz-animation: bounce 2s infinite;
  -webkit-animation: bounce 2s infinite;
  animation: bounce 2s infinite;
}

@keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-30px);
  }
  60% {
    transform: translateY(-20px);
  }
}