.fader {
    width: 100wh;
    height: 100vh;
    color: #fff;
    background: linear-gradient(-45deg, #000000, #ffc107, #4a4b4b, #000000);
    background-size: 300% 300%;
    -webkit-animation: Gradient 6s ease infinite;
    -moz-animation: Gradient 6s ease infinite;
    animation: Gradient 6s ease infinite;
    }
  
  @-webkit-keyframes Gradient {
    0% {
    background-position: 0% 50%
    }
  50% {
  background-position: 100% 50%
  }
  100% {
  background-position: 0% 50%
  }
  }
  
  @-moz-keyframes Gradient {
    0% {
    background-position: 0% 50%
    }
  50% {
  background-position: 100% 50%
  }
  100% {
  background-position: 0% 50%
  }
  }
  
  @keyframes Gradient {
    0% {
    background-position: 0% 50%
    }
  50% {
  background-position: 100% 50%
  }
  100% {
  background-position: 0% 50%
  }
  }
  